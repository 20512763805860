export class Constants
{
  static  tags : Array<string> = ['DEVOPS', 'CLOUD', 'AWS','AZURE', 'GCP', 'GIT', 'Ansible', 'Terraform', 'DEV', 'JAVA', 'Angular', 'ReactJS', 'Kotlin', 'PHP', 'GITLAB', 'CI/CDD', 'GITHUB', 'JENKINS', 'DOCKER'];
  
  static pages : Array<string> =  [
    'FORMATION',
    'PRESTATION',
    'PRESENTATION',
    'FAQ',
    'RGPD',
    'CGU',
    'CGV',
    'LEGAL',
    'TEAMDEVOPS',
  ];


}
  